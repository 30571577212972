import React, { createContext, useState, useEffect } from "react";
export const AppContext = createContext();
export const AppProvider = ({ children }) => {
    // const [loginbanner, setLoginBanner] = useState(null);
    // const [balanceAmount, setBalanceAmount] = useState(0);
    // const [isLightMode, setIsLightMode ] = useState(false);
    // const [bonusAmount, setBonusAmount] = useState(0);
    // const [totalExposure , setTotalExposure] = useState(0);
    // const [stacks, setStacks] = useState([]);
    const [userData, setUserData] = useState(null);
    // const login = (userData, token) => {
    //   setUser(userData);  // Save user data to state
    //   setToken(token);     // Save token to state
    //   setIsAuthenticated(true); // Update the authentication state

    return (
        <AppContext.Provider value={{ userData, setUserData, 
          // loginbanner , setLoginBanner,
          // balanceAmount, setBalanceAmount,
          // isLightMode, setIsLightMode, 
          // bonusAmount,setBonusAmount,
          // totalExposure , setTotalExposure
          }}>
          {children}
        </AppContext.Provider>
      );
    };
