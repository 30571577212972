import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AppProvider } from './Pages/contexts/AppContext';
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";

ReactDOM.render(
  <React.Fragment>
    <AppProvider>
    <App />
    </AppProvider>
  </React.Fragment>,
  document.getElementById('root')
);
